<template>
  <div class="content">
    <div class="h3 text-white font-weight-bold text-center my-5">
      用52skins合伙人代码或链接
    </div>
      <div class="row">
          <div class="col-md-6 pr-2">
              <div class="d-flex align-items-center px-2 input " style="">
                  <div><i class="el-icon-paperclip"></i></div>
                  <div class=" px-3 pt-3" style="width: 80%">
                      <div class="mb-2" style="font-size: 12px">你的合伙人链接</div>
                      <div class="py-1">
                          {{invite_url}}{{user.invite_code}}
                      </div>
                  </div>
                  <div style="font-size: 12px" class="cursor-pointer" @click="authCopy">复制链接</div>
              </div>
          </div>
          <div class="col-md-6 pl-2">
              <div class="d-flex align-items-center px-2 input " style="">
                  <div><i class="el-icon-paperclip"></i></div>
                  <div class=" px-3 pt-3" style="width: 80%">
                      <div class="mb-2" style="font-size: 12px">你的合伙人链接</div>
                      <div>
                          <input type="text" class="w-100 text-white" v-model="user.invite_code" >
                      </div>
                  </div>
                  <div class="text-right cursor-pointer" style="font-size: 12px;width: 50px" @click="amendCopy">联系客服</div>
              </div>
          </div>
      </div>


    <table cellspacing="0" cellpadding="0" border="0" class="mt-3" style="width: 100%;">
      <thead class="">
          <tr class="text-danger" style="font-size: 12px">
              <th colspan="1" rowspan="1">
                  <div class="cell">你的等级</div>
              </th>
              <th colspan="1" rowspan="1">
                  <div class="cell">被引荐者</div>
              </th>
              <th colspan="1" rowspan="1">
                  <div class="cell">邀请总人数</div>
              </th>
              <th colspan="1" rowspan="1">
                  <div class="cell">当前返佣比例</div>
              </th>

              <th colspan="1" rowspan="1">
                  <div class="cell">下线总充值</div>
              </th>
          </tr>
      </thead>
      <tr class="bg-bg" style="">
        <td rowspan="1" colspan="1">
          <div class="cell d-flex align-items-center">
            <div class="mr-2 text-center rounded-circle"
                 style="width: 30px;height: 30px;line-height: 30px;border: 2px solid var(--main-yellow)">
              {{user.promotion_level}}</div>
          </div>
        </td>
        <td rowspan="1" colspan="1">
          <div class="cell d-flex">
            {{user_info.inviter != null?'' + user_info.inviter.name : '暂无'}}
          </div>
        </td>
        <td rowspan="1" colspan="1">
            <div class="cell">{{user.people_invited_num}}人</div>
        </td>
        <td rowspan="1" colspan="1">
            <div class="cell">{{user.promotion?user.promotion.rebate:'0.00'}}%</div>
        </td>
        <td rowspan="1" colspan="1">
            <div class="cell"> <money/> {{(user.invite_total_recharge)}}</div>
        </td>
      </tr>
    </table>
    <div class="text-center mt-5">
        总返佣：<money/> {{(user.invite_total_recharge * user.promotion.rebate)*(user.promotion.level)/100 || '0'}}
    </div>
    <div class="highlighted-message text-center my-5">
        <p class="highlighted-message-body">主播合作请联系客服。请勿恶意推广，52skins保留最终解释权。需要帮助请联系{{web_info.qq_group_number}}</p>
    </div>
    <div class="h3 text-white font-weight-bold text-center my-5">
      合伙人等级
    </div>
    <div class="mt-3  ">
      <table cellspacing="0" cellpadding="0" border="0" class="" style="width: 100%;">
        <colgroup>
          <col width="50">
          <col width="300">
          <col width="300">
          <col width="300">
        </colgroup>
        <thead class="text-danger">
        <tr class="grade">
          <th colspan="1" rowspan="1">
            <div class="cell">级别</div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell">下级需要</div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell">充值返佣</div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell">说明</div>
          </th>
        </tr>
        </thead>
        <tr class="grade bg-bg" v-for="(item,index) in list" :key="index">
          <td rowspan="1" colspan="1">
            <div class="cell d-flex align-items-center">
              <div class="mr-2 text-center rounded-circle"
                   style="font-size: 12px;width: 30px;height: 30px;line-height: 30px;border: 2px solid var(--main-yellow)">
                {{item.name}}</div>
            </div>
          </td>
          <td rowspan="1" colspan="1">
            <div class="cell">

              <money/>{{item.invite_total}}
            </div>
          </td>
          <td rowspan="1" colspan="1">
            <div class="cell">{{item.rebate + "%"}}</div>
          </td>
          <td rowspan="1" colspan="1">
            {{item.description}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import $api from '@/api/user/index'

  export default {
    name: "index",
    components: {},
    data() {
      return {
        invite_url: '//52skins.com/?ref_code=',
        resData: {},
        list: [],
        user: {},


      }
    },
    computed: {
        invite_url_mode(){
            return this.invite_url + this.user.invite_code
        },
      ...mapState(['user_info','web_info'])
    },
    created() {
      setTimeout(() => {
        this.resData = this.user_info
      }, 500)
    },
    mounted() {
      this.init()
      setTimeout(() => {
          let host = window.location.host;
          this.invite_url = 'https://' + host + '/?ref_code='
      }, 100)
    },
    methods: {
      init() {
        this.getPromote()
      },
      authCopy() {
        this.$common.CopyText({content: this.invite_url + this.resData.invite_code})
      },
      async getPromote() {
        try {
          let {data, code} = await $api.getPromote()
          if (code == 500) return
          this.list = data.list
          this.user = data.user
        } catch (e) {
          console.log(e)
        }
      },
      async amendCopy() {
  
      }
    }
  }
</script>

<style scoped lang="scss">
  .grade {
    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }

  .input-code {
    @media (max-width: 1200px) {
      width: 150px !important;
    }
  }

  .tong-height {
    height: 38px;
    line-height: 38px;
  }

  .side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
  }

  .content {
    width: 90%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 96%;
      font-size: 12px !important;
    }
  }

  .cell {
    padding: 20px;

    @media (max-width: 1200px) {
      padding: 5px;
    }
  }
  .highlighted-message {
    padding: 19px 5px 19px 16px;
    border: 1px solid #fada62;
    border-radius: 8px;
    background-color: rgba(250,218,98,0.12);
  }

</style>
